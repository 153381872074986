import React, { useState } from 'react'
import axios from 'axios'
import { Image, Table } from 'react-bootstrap'

const BookFinder = () => {
	const [book, setBook] = useState('')
	const [result, setResult] = useState([])

	const handleChange = (event) => {
		const book = event.target.value

		setBook(book)
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		axios
			.get(
				'https://www.googleapis.com/books/v1/volumes?q=' +
					book +
					'&filter=partial&maxResults=10'
			)
			.then((response) => {
				console.log(response.data.items)
				setResult(response.data.items)
			})
	}

	return (
		<div className='container'>
			<h2>Search Google Books</h2>
			<form onSubmit={handleSubmit}>
				<div className='form-group'>
					<input
						type='text'
						onChange={handleChange}
						className='form-control mt-10'
						placeholder='Search for books'></input>
				</div>
				<button type='submit' className='btn btn-danger'>
					Search
				</button>
			</form>
			<Table striped bordered hover variant='dark' size='sm'>
				<tbody>
					<tr>
						<th></th>
						<th>Title</th>
						<th>Subtitle</th>
						<th>Pages</th>
						<th>Authors</th>
						<th>Published</th>
						<th>Publisher</th>
						<th>ISBN 13</th>
					</tr>
					{result.map((book, id) => (
						<tr key={id}>
							<td>
								<a
									href={book.volumeInfo.previewLink}
									target='_blank'
									rel='noopener noreferrer'>
									<Image
										className='SinglePhoto'
										src={
											book.volumeInfo.imageLinks &&
											book.volumeInfo.imageLinks.thumbnail
										}
										alt={book.volumeInfo.title}
									/>
								</a>
							</td>
							<td>{book.volumeInfo.title}</td>
							<td>{book.volumeInfo.subtitle}</td>
							<td>{book.volumeInfo.pageCount}</td>
							<td>
								{book.volumeInfo.authors[0]}, {book.volumeInfo.authors[1]}
							</td>
							<td>{book.volumeInfo.publishedDate}</td>
							<td>{book.volumeInfo.publisher}</td>
							<td>
								{book.volumeInfo.industryIdentifiers[0] &&
									book.volumeInfo.industryIdentifiers[0].identifier}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	)
}

export default BookFinder
