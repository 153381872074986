import { Table } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
//import PageItem from 'react-bootstrap/PageItem'

const Google = () => {
	const [books, setBooks] = useState([])
	const [totalPages, setTotalPages] = useState()
	const [currentPage, setCurrentPage] = useState(0)
	const [page, setPage] = useState(1)

	let store = 'Google'

	useEffect(() => {
		/*
		const fetchBooks = async () => {
			axios.get(`https://books.eastdonriver.ca/api/ebooks`).then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
		}
		*/

		const fetchBooks = async () => {
			axios
				.get(`https://library.eastdonriver.ca/api/books/?store=${store}`)
				.then((response) => {
					setBooks(response.data.books)
					setTotalPages(response.data.totalPages)
					setCurrentPage(response.data.currentPage)
				})
		}

		fetchBooks()
	}, [])

	function nextPage() {
		if (page < totalPages) {
			setPage(page + 1)
			var nextPageQuery = currentPage + 1
		} else {
			setPage(totalPages)
			nextPageQuery = currentPage
		}

		axios
			.get(
				`https://library.eastdonriver.ca/api/books/?store=${store}&page=${nextPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function previousPage() {
		if (currentPage >= 1) {
			var prevPageQuery = currentPage - 1
			setPage(page - 1)
		} else {
			prevPageQuery = 0
			setPage(1)
		}

		axios
			.get(
				`https://library.eastdonriver.ca/api/books/?store=${store}&page=${prevPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function pagination(number) {
		var queryPage = number - 1
		axios
			.get(
				`https://library.eastdonriver.ca/api/books/?store=${store}&page=${queryPage}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setCurrentPage(response.data.currentPage)
			})
	}

	let pages = []
	for (let number = 1; number <= totalPages; number++) {
		pages.push(
			<Pagination.Item
				key={number}
				active={number === currentPage + 1}
				onClick={() => pagination(number)}>
				{number}
			</Pagination.Item>
		)
	}

	return (
		<div>
			<h2>My Google Play Books</h2>
			<div className='container d-flex justify-content-center'>
				<Pagination size='m'>
					<Pagination.Prev onClick={previousPage} />
					{pages}
					<Pagination.Next onClick={nextPage} />
				</Pagination>
			</div>
			<Table striped bordered hover variant='dark' size='sm'>
				<tbody>
					<tr>
						<th>Title</th>
						<th>Author</th>
						<th>Store</th>
						<th>Published</th>
					</tr>
					{books.map((book, id) => (
						<tr key={id}>
							<td>{book.Title}</td>
							<td>{book.Author}</td>
							<td>{book.Store}</td>
							<td>{book.Published}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	)
}

export default Google
