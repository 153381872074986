import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoggedInHomeScreen from './LoggedInHomeScreen'
import LoggedOutHomeScreen from './LoggedOutHomeScreen'

const HomeScreen = () => {
	const { isLoading } = useAuth0()

	if (isLoading) return <div>Loading...</div>

	return (
		<div>
			<LoggedInHomeScreen />
			<LoggedOutHomeScreen />
		</div>
	)
}

export default HomeScreen
