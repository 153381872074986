import axios from 'axios'
import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'

const DataForm = () => {
	const [title, setTitle] = useState('')
	const [subtitle, setSubtitle] = useState('')
	const [author, setAuthor] = useState('')
	const [pages, setPages] = useState('')
	const [year, setYear] = useState('')
	const [store, setStore] = useState('')
	const [publisher, setPublisher] = useState('')
	const [published, setPublished] = useState('')
	const [ISBN10, setISBN10] = useState('')
	const [ISBN13, setISBN13] = useState('')
	const [cover, setCover] = useState('')
	const [subject, setSubject] = useState('')

	const addBook = () => {
		axios.post('https://library.eastdonriver.ca/api/books', {
			title: title,
			subtitle: subtitle,
			author: author,
			pages: pages,
			year: year,
			store: store,
			publsher: publisher,
			published: published,
			ISBN10: ISBN10,
			ISBN13: ISBN13,
			cover: cover,
			subject: subject,
		})
	}

	return (
		<div>
			<Container>
				<Form onSubmit={addBook}>
					<Row className='mb-3'>
						<Form.Group as={Col} controlId='text'>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Title'
								onChange={(event) => {
									setTitle(event.target.value)
								}}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId='text'>
							<Form.Label>Subtitle</Form.Label>
							<Form.Control
								type='text'
								placeholder='Subtitle'
								onChange={(event) => {
									setSubtitle(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>

					<Row className='mn-3'>
						<Form.Group as={Col} controlId='text'>
							<Form.Label>Author</Form.Label>
							<Form.Control
								type='text'
								placeholder='Author'
								onChange={(event) => {
									setAuthor(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Published</Form.Label>
							<Form.Control
								placeholder='YYYY-MM-DD'
								onChange={(event) => {
									setPublished(event.target.value)
								}}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Bookstore</Form.Label>
							<Form.Control
								type='text'
								placeholder='Amazon Kindle, Google Play, etc.'
								onChange={(event) => {
									setStore(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>

					<Row className='mb-3'>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Year</Form.Label>
							<Form.Control
								type='number'
								placeholder='Year'
								onChange={(event) => {
									setYear(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Pages</Form.Label>
							<Form.Control
								type='number'
								placeholder='Pages'
								onChange={(event) => {
									setPages(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Publisher</Form.Label>
							<Form.Control
								type='text'
								placeholder='Publisher'
								onChange={(event) => {
									setPublisher(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>ISBN10</Form.Label>
							<Form.Control
								placeholder='ISBN10'
								onChange={(event) => {
									setISBN10(event.target.value)
								}}
							/>
						</Form.Group>

						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>ISBN13</Form.Label>
							<Form.Control
								placeholder='ISBN13'
								onChange={(event) => {
									setISBN13(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>

					<Row className='mb-3'>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Subject</Form.Label>
							<Form.Control
								type='text'
								placeholder='Subject'
								onChange={(event) => {
									setSubject(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Cover File</Form.Label>
							<Form.Control
								type='text'
								placeholder='/images/'
								onChange={(event) => {
									setCover(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>

					<Button variant='primary' type='submit'>
						Submit
					</Button>
				</Form>
			</Container>
		</div>
	)
}

export default DataForm
