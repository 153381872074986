import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import './Book.css'

const Book = ({ book }) => {
	return (
		<div>
			<Card className='HomeCards' style={{ backgroundColor: '#282c34' }}>
				<Link to={`/book/${book.id}`}>
					<Card.Img className='Photo' src={book.Cover} variant='top' />
				</Link>
			</Card>
		</div>
	)
}

export default Book
