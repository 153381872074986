import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Library from './Library'

const LoggedInHomeScreen = () => {
	const { isAuthenticated } = useAuth0()
	return (
		isAuthenticated && (
			<div>
				<Library />
			</div>
		)
	)
}

export default LoggedInHomeScreen
