import React from 'react'
import { Table } from 'react-bootstrap'

const Statistics = ({ books }) => {
	const findAveragePages = (arr) => {
		const { length } = arr
		return arr.reduce((acc, val) => {
			return acc + val.Pages / length
		}, 0)
	}

	const maxPages = Math.max(...books.map((item) => item.Pages))
	const minPages = Math.min(...books.map((item) => item.Pages))
	const avgPages = Math.round(findAveragePages(books))
	const newestYear = Math.max(...books.map((item) => item.Year))
	const oldestYear = Math.min(...books.map((item) => item.Year))

	//console.log(avgPages)

	return (
		<div>
			<Table striped bordered hover variant='dark'>
				<tbody>
					<tr>
						<td>Least Pages: {minPages}</td>
						<td>Most Pages: {maxPages}</td>
						<td>Average Pages: {avgPages}</td>
						<td>Oldest Release: {oldestYear}</td>
						<td>Newest Release: {newestYear}</td>
					</tr>
				</tbody>
			</Table>
		</div>
	)
}

export default Statistics
