import React from 'react'

const Footer = () => {
	const thisYear = new Date().getFullYear()

	return (
		<div>
			<footer className='footer'>&copy; {thisYear} Dunlevy Publishing</footer>
			<p className='footer'>
				<a href='https://whc.ca/green-powered/?aff=3153&gbid=1en'>
					<img
						src='https://s.whc.ca/badges/green-badge-1.svg'
						width='130'
						alt='Green Hosting Badge'
					/>
				</a>
			</p>
		</div>
	)
}

export default Footer
