import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card } from 'react-bootstrap'
//import { Row, Col, Image, Card, Button } from 'react-bootstrap'
import axios from 'axios'
import '../components/Book.css'

const BookScreen = ({ match }) => {
	const [book, setBook] = useState({})
	const { id } = useParams()

	useEffect(() => {
		const fetchBook = async () => {
			axios
				.get(`https://library.eastdonriver.ca/api/books/${id}`)
				.then((response) => {
					setBook(response.data)
				})
		}

		fetchBook()
	}, [match])

	//console.log(book)

	return (
		<div className='MoviePage'>
			<Card>
				<Link to={`/africanbooks2`}>
					<Card.Img variant='top' src={book.Cover} />
				</Link>
				<Card.Body>
					<Card.Title>{book.Title}</Card.Title>
					<Card.Subtitle>{book.Subtitle}</Card.Subtitle>
					<Card.Text>{`by ${book.Author}`}</Card.Text>
					<Card.Text>{`Published: ${book.Year} by ${book.Publisher}`}</Card.Text>
					<Card.Text>{`${book.Pages} pages`}</Card.Text>
					<Card.Text>{`ISBN10: ${book.ISBN10}`}</Card.Text>
					<Card.Text>{`ISBN13: ${book.ISBN13}`}</Card.Text>
					<Link className='btn btn-block btn-primary my-3' to='/africanbooks2'>
						Back
					</Link>
				</Card.Body>
			</Card>
		</div>
	)
}

export default BookScreen
