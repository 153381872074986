import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'
import { Table, Image } from 'react-bootstrap'
import Statistics from './Statistics'

const AfricanBooks = () => {
	const [books, setBooks] = useState([])
	const [totalPages, setTotalPages] = useState()
	const [currentPage, setCurrentPage] = useState(0)
	const [page, setPage] = useState(1)

	const africanBooks = books

	useEffect(() => {
		const fetchBooks = async () => {
			axios
				.get(`https://library.eastdonriver.ca/api/books/?subject=Africa`)
				.then((response) => {
					setBooks(response.data.books)
					setTotalPages(response.data.totalPages)
					setCurrentPage(response.data.currentPage)
				})
		}

		fetchBooks()
	}, [])

	function nextPage() {
		if (page < totalPages) {
			setPage(page + 1)
			var nextPageQuery = currentPage + 1
		} else {
			setPage(totalPages)
			nextPageQuery = currentPage
		}

		axios
			.get(
				`https://library.eastdonriver.ca/api/books?subject=Africa&page=${nextPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function previousPage() {
		if (currentPage >= 1) {
			var prevPageQuery = currentPage - 1
			setPage(page - 1)
		} else {
			prevPageQuery = 0
			setPage(1)
		}

		axios
			.get(
				`https://library.eastdonriver.ca/api/books?subject=Africa&page=${prevPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function pagination(number) {
		var queryPage = number - 1
		axios
			.get(
				`https://library.eastdonriver.ca/api/books?subject=Africa&page=${queryPage}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setCurrentPage(response.data.currentPage)
			})
	}

	let pages = []
	for (let number = 1; number <= totalPages; number++) {
		pages.push(
			<Pagination.Item
				key={number}
				active={number === currentPage + 1}
				onClick={() => pagination(number)}>
				{number}
			</Pagination.Item>
		)
	}

	return (
		<div>
			<h2>My African Books</h2>
			<div className='container d-flex justify-content-center'>
				<Pagination size='m'>
					<Pagination.Prev onClick={previousPage} />
					{pages}
					<Pagination.Next onClick={nextPage} />
				</Pagination>
			</div>
			<Statistics books={books} />
			<Table striped bordered hover variant='dark' size='sm'>
				<tbody>
					<tr>
						<th>Cover</th>
						<th>Title</th>
						<th>Subtitle</th>
						<th>Author</th>
						<th>Pages</th>
						<th>Year</th>
						<th>Publisher</th>
						<th>Published</th>
					</tr>
					{africanBooks.map((book, id) => (
						<tr key={id}>
							<td>
								<Image
									//className='SinglePhoto'
									width={150}
									src={book.Cover}
									alt={book.Title}
								/>
							</td>
							<td>{book.Title}</td>
							<td>{book.Subtitle}</td>
							<td>{book.Author}</td>
							<td>{book.Pages}</td>
							<td>{book.Year}</td>
							<td>{book.Publisher}</td>
							<td>{book.Published}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	)
}

export default AfricanBooks
