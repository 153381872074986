import React from 'react'
import { Image } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'

const LoggedOutHomeScreen = () => {
	const { isAuthenticated } = useAuth0()
	return (
		!isAuthenticated && (
			<div>
				<h2>Dunlevy Ebook Database</h2>
				<p>Please log in to access.</p>
				<Image src='/images/pexels-rafael-cosquiere-2041540.jpg' />
			</div>
		)
	)
}

export default LoggedOutHomeScreen
