import React from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import { Link } from 'react-router-dom'

const Navigation = () => {
	const { isAuthenticated } = useAuth0()
	return (
		<div>
			<Navbar bg='primary' variant='dark'>
				<Container>
					{isAuthenticated ? (
						<>
							<Navbar.Brand as={Link} to='/'>
								<img
									src='/images/DP.png'
									width='55'
									height='55'
									className='d-inline-block align-top'
									alt='Dunlevy Publishing logo'></img>
							</Navbar.Brand>
							<Nav className='me-auto'>
								<Nav.Link as={Link} to='/kindle'>
									Amazon Kindle
								</Nav.Link>
								<Nav.Link as={Link} to='/google'>
									Google Play Books
								</Nav.Link>
								<Nav.Link as={Link} to='/searchlibrary'>
									Search
								</Nav.Link>
								<NavDropdown title='Extras' id='basic-nav-dropdown'>
									<NavDropdown.Item as={Link} to='/africanbooks2'>
										African Books
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/africanbooks'>
										African Books Detailed
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/search'>
										Google Books Search
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to='/dataManager'>
										Data Manager
									</NavDropdown.Item>
								</NavDropdown>
								<LogoutButton />
							</Nav>
						</>
					) : (
						<LoginButton />
					)}
				</Container>
			</Navbar>
		</div>
	)
}

export default Navigation
