import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import './App.css'
import React from 'react'
//import React, { useState, useEffect } from 'react'
//import axios from 'axios'
//import Library from './screens/Library'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import Google from './screens/Google'
import Kindle from './screens/Kindle'
import DataManager from './screens/DataManager'
import AfricanBooks from './screens/AfricanBooks'
import BookFinder from './screens/BookFinder'
import Statistics from './screens/Statistics'

import SearchLibrary from './screens/SearchLibrary'
import AfricanBooks2 from './screens/AfricanBooks2'
import BookScreen from './screens/BookScreen'
import { ProtectedRoute } from './components/ProtectedRoute'
import HomeScreen from './screens/HomeScreen'

function App() {
	/* const [books, setBooks] = useState([])

	useEffect(() => {
		const fetchBooks = async () => {
			axios
				.get(`https://library.eastdonriver.ca/api/books/`)
				.then((response) => {
					setBooks(response.data.books)
				})
		}

		fetchBooks()
	}, [])
	*/
	return (
		<Router>
			<Navigation />
			<Container>
				<Routes>
					<Route path='/' element={<HomeScreen />} />
					<Route
						path='/searchlibrary'
						element={<ProtectedRoute component={SearchLibrary} />}
					/>
					<Route
						path='/kindle'
						element={<ProtectedRoute component={Kindle} />}
					/>
					<Route
						path='/google'
						element={<ProtectedRoute component={Google} />}
					/>
					<Route
						path='/africanbooks'
						element={<ProtectedRoute component={AfricanBooks} />}
					/>
					<Route
						path='/africanbooks2'
						element={<ProtectedRoute component={AfricanBooks2} />}
					/>
					<Route
						path='/search'
						element={<ProtectedRoute component={BookFinder} />}
					/>
					<Route
						path='/dataManager'
						element={<ProtectedRoute component={DataManager} />}
					/>
					<Route
						path='/stats'
						element={<ProtectedRoute component={Statistics} />}
					/>
					<Route
						path='/book/:id'
						element={<ProtectedRoute component={BookScreen} match />}
					/>
				</Routes>
			</Container>
			<Footer />
		</Router>
	)
}

export default App
