import React from 'react'
import DataForm from '../components/DataForm'
import '../style/DataManager.css'

const DataManager = () => {
	return (
		<div>
			<h2>Data Manager</h2>
			<DataForm />
		</div>
	)
}

export default DataManager
