//import { Table } from 'react-bootstrap'
import { Table, Form, Container, Row, Col, Button } from 'react-bootstrap'
import React, { useState } from 'react'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'

const SearchLibrary = () => {
	const [books, setBooks] = useState([])
	const [totalPages, setTotalPages] = useState()
	const [currentPage, setCurrentPage] = useState(0)
	const [page, setPage] = useState(1)

	const [title, setTitle] = useState('')
	const [author, setAuthor] = useState('')
	const [store, setStore] = useState('')
	const [condition, setCondition] = useState('')

	/*
	useEffect(() => {
		const fetchBooks = async () => {
			axios.get(`http://localhost:8080/api/books`).then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
		}

		fetchBooks()
	}, [])
	*/

	const handleSearch = (event) => {
		event.preventDefault()

		let query =
			title && author
				? `?title=${title}&author=${author}`
				: title
				? `?title=${title}`
				: author
				? `?author=${author}`
				: store
				? `?store=${store}`
				: ''

		setCondition(query)
		console.log(condition)

		axios
			.get(`https://library.eastdonriver.ca/api/books/${query}`)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function nextPage() {
		if (page < totalPages) {
			setPage(page + 1)
			var nextPageQuery = currentPage + 1
		} else {
			setPage(totalPages)
			nextPageQuery = currentPage
		}
		console.log(condition)

		axios
			.get(
				`https://library.eastdonriver.ca/api/books/${condition}&page=${nextPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function previousPage() {
		if (currentPage >= 1) {
			var prevPageQuery = currentPage - 1
			setPage(page - 1)
		} else {
			prevPageQuery = 0
			setPage(1)
		}

		axios
			.get(
				`https://library.eastdonriver.ca/api/books/${condition}&page=${prevPageQuery}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setTotalPages(response.data.totalPages)
				setCurrentPage(response.data.currentPage)
			})
	}

	function pagination(number) {
		var queryPage = number - 1
		axios
			.get(
				`https://library.eastdonriver.ca/api/books/${condition}&page=${queryPage}`
			)
			.then((response) => {
				setBooks(response.data.books)
				setCurrentPage(response.data.currentPage)
			})
	}

	let pages = []
	for (let number = 1; number <= totalPages; number++) {
		pages.push(
			<Pagination.Item
				key={number}
				active={number === currentPage + 1}
				onClick={() => pagination(number)}>
				{number}
			</Pagination.Item>
		)
	}

	return (
		<div>
			<h2>Search My Library</h2>
			<Container>
				<Form onSubmit={handleSearch}>
					<Row className='mb-3'>
						<Form.Group as={Col} controlId=''>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Title'
								onChange={(event) => {
									setTitle(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridPassword'>
							<Form.Label>Author</Form.Label>
							<Form.Control
								type='text'
								placeholder='Author'
								onChange={(event) => {
									setAuthor(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridCity'>
							<Form.Label>Bookstore</Form.Label>
							<Form.Control
								placeholder='Amazon Kindle, Google Play, etc.'
								onChange={(event) => {
									setStore(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>
					<Button variant='primary' type='submit'>
						Search Library
					</Button>
					<Button variant='secondary' type='reset'>
						Clear Form
					</Button>
				</Form>
			</Container>
			<div className='container d-flex justify-content-center'>
				<Pagination size='m'>
					<Pagination.Prev onClick={previousPage} />
					{pages}
					<Pagination.Next onClick={nextPage} />
				</Pagination>
			</div>
			<Table striped bordered hover variant='dark' size='sm'>
				<tbody>
					<tr>
						<th>Title</th>
						<th>Author</th>
						<th>Store</th>
						<th>Published</th>
					</tr>
					{books.map((book, id) => (
						<tr key={id}>
							<td>{book.Title}</td>
							<td>{book.Author}</td>
							<td>{book.Store}</td>
							<td>{book.Published}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	)
}

export default SearchLibrary
